import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { JSONLD, Generic } from 'react-structured-data';
import { withPreview } from 'gatsby-source-prismic';
import { place } from '../utils/helpers';
import config from '../utils/config';

import Seo from '../components/Seo';
import SocialShare from '../components/SocialShare';

const Event = ({ data }) => {
  const content = data.prismicEvent.data;

  const title = content.city;
  return (
    <div>
      <h1 className="page-header">
        <Link to="/concert-dates">Concert Dates</Link>
      </h1>
      <div
        data-aos="fade-in"
        data-aos-delay="150"
        className="page-content"
      >
        <article className="node concert">
          <div className="row">
            <div className="columns col-1 small-12 medium-6">
              <header>
                <small className="ish">{content.country}
                <span className="hide-for-medium-up"> - {content.city}</span></small>
                <h2>
                  <time
                    className="bumper success"
                    dateTime={content.date_alt}
                  >
                    {content.date_beginning}
                  </time>
                  <span>{content.city}</span>
                </h2>
              </header>
            </div>
            <div className="columns col-2 small-12 medium-6">
              <span className="venue">
                {content.venue}
                <br />
                {content.city}, {content.country}
              </span>
              <span
                className="description"
                dangerouslySetInnerHTML={{ __html: content.description.html }}
              />
              {content.tickets
                && (
              <span className="tickets">
                <a
                  href={content.tickets.url}
                >
                    Get tickets
                </a>
              </span>
              )}
            </div>
            <SocialShare
              title={`Concert: JLP in ${content.city}, ${content.country}`}
              url={data.prismicEvent.url}
            />
          </div>
          <JSONLD dangerouslyExposeHtml={true}>
            <Generic
              type="MusicEvent"
              jsonldtype="MusicEvent"
              schema={{
                name: `Jean Luc Ponty at ${content.venue}`,
                startDate: content.date_alt,
                endDate: content.date_alt,
                description: content.description.text,
                image: [config.siteImage],
              }}
            >
              <Generic
                type="location"
                jsonldtype="Place"
                schema={{
                  name: content.venue,
                }}
              >
                <Generic
                  type="address"
                  jsonldtype="PostalAddress"
                  schema={{
                    addressLocality: content.city,
                    addressRegion: content.state,
                    addressCountry: content.country,
                  }}
                />
              </Generic>
              <Generic
                type="offers"
                jsonldtype="Offer"
                schema={{
                  url: content.tickets ? content.tickets.url : null,
                }}
              />
              <Generic
                type="performer"
                jsonldtype="Person"
                schema={{
                  name: 'Jean Luc Ponty',
                }}
              />
            </Generic>
          </JSONLD>
        </article>
      </div>
      <Seo
        slug={data.prismicEvent.url}
        title={`Concert: JLP in ${content.city}, ${content.country}`}
        description={`Jean Luc Ponty concert at ${content.venue}, ${content.city}, ${content.country} on ${content.date_beginning}`}
      />
    </div>
  );
};

Event.propTypes = {
  data: PropTypes.object,
};

export default withPreview(Event);

export const query = graphql`
  query (
    $prismicId: ID!
  ) {
    prismicEvent(
      prismicId: {
        eq: $prismicId
      }
    ) {
      url
      prismicId
      data {
        city
        state
        country
        venue
        tickets {
          url
        }
        date_beginning(formatString: "MMM D, YYYY")
        date_alt: date_beginning(
          formatString: "YYYY-MM-DD"
        )
        description {
          html
          text
        }
      }
    }
  }
`;
